<template>
	<div class="container sector-selector-container" v-loading="loading">
        <h1>{{ $t('sector-selector-title') }}</h1>

        <div class="sectors">
            <div :class="'sector ' + sector.class" v-for="(sector, skey) in sectors" :key="'floor-'+skey">
                <h4 v-t="sector.floorName">{{ sector.floorName }} </h4>

                <div class="options">
                    <div :class="{'option': true, 'selected': option.id == selectedBuffetGroup, 'disabled': !anyOpenBuffet(option.id)}"
                        v-for="(option, okey) in sector.options"
                        :key="'option-'+skey+okey"
                        @click="anyOpenBuffet(option.id) && selectBuffetGroup(option.id)"
                        v-t="option.label">
                    </div>
                </div>
            </div>
        </div>

        <div :class="{'button-overlay': true, 'visible': selectedBuffetGroup}" v-if="selectedBuffetGroup">
            <div class="text">
                <span>{{ $t('sector-number-selected', {num: selectedBuffetGroupText}) }}</span>
            </div>
            <div class="button">
                <button class="yellowBtn" @click="gotoBuffets()">
                    {{ $t('sector-selector-btn-next') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {getBuffets} from "../../functions/api/content";

export default {
    data() {
        return {
            loading: null,
            sectors: [{
                    "floorName": "sector-floor-0",
                    "options": [{
                        id: "Bejárat",
                        label: "sector-floor-0-option-0"
                    }, {
                        id: "Küzdőtér",
                        label: "sector-floor-0-option-1"
                    }],
                    class: 'floor-0',
                }, {
                    "floorName": "sector-floor-1",
                    "options": [],
                    class: 'floor-1',
                }, {
                    "floorName": "sector-floor-2",
                    "options": [],
                    class: 'floor-2',
                }]
        }
    },
    computed: {
        selectedBuffetGroup() {
            return this.$store.state.selectedBuffetGroup;
        },
        selectedBuffetGroupText() {
            return this.$t(this.$store.state.selectedBuffetGroup);
        },
        selectedFloorText() {
            let selected = this.floors.find(row => row.floor == this.selectedFloor);

            if(selected) {
                return this.floors.find(row => row.floor == this.selectedFloor).name
            }

            return 'Nincs kiválasztva';
        },        
    },
    created() {
        this.$store.commit('hideNavigation');
        this.$store.commit('removeHeaderLink')
        this.$store.commit('setSkyboxTitle', 'sector-selector-page-title')
        this.$store.commit('setSkyboxText', '')

        setTimeout(() => {
            this.$store.commit('setApploading', false);
        }, 5000);

        this.refreshBuffets()
				.then(() => {
					if(this.$store.getters.allBuffetsClosed) {
						this.$router.replace('/app-closed');
					}
				});

        if (this.selectedBuffetGroup) {
            if (!this.anyOpenBuffet(this.selectedBuffetGroup)){
                this.$store.dispatch('selectBuffetGroup', null);
            }
        }

        this.generateSectors();
        this.$store.dispatch('loadSectorLocations');
    },
    methods: {
        selectBuffetGroup(groupKey) {
            this.$store.dispatch('selectBuffetGroup', groupKey);
        },
        gotoBuffets() {
            if(this.selectedBuffetGroup) {
                this.$router.push('/buffet-list');
            }
        },
        generateSectors() {
            for(let i = 101; i <= 116; i++) {
                this.sectors[1].options.push({
                    id: i.toString(),
                    label: i.toString(),
                })
            }
            for(let i = 201; i <= 216; i++) {
                this.sectors[1].options.push({
                    id: i.toString(),
                    label: i.toString(),
                })
            }
            for(let i = 301; i <= 320; i++) {
                this.sectors[2].options.push({
                    id: i.toString(),
                    label: i.toString(),
                })
            }
        },
        refreshBuffets() {
            this.loading = true;
            return getBuffets(this.$store.state.mvmId).then(res => {                
                this.$store.commit('setBuffets', res);
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.error(err);
            })
        },
        anyOpenBuffet(buffetGroupId) {
            return this.$store.getters.visibleBuffetsByBuffetGroup(buffetGroupId).some(b => typeof b.open !== undefined && b.open === true);
        }
    }
}
</script>